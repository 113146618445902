import React, { useEffect, useState, useRef, useMemo } from 'react';
import Box from '@mui/material/Box';
import Avatar from "../../../assets/img/avatar.png"
import { __API_URL__ } from "../../../SERVER_URL";
import { useNavigate } from "react-router-dom";
import useUser from "../../../hooks/user";
import { ApiManager } from '../../../servicemanager/apimanager';
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, getGridStringOperators, getGridNumericOperators, useGridApiRef } from '@mui/x-data-grid-pro';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CustomDensitySelector from './CustomDensitySelector';
import { toast, ToastContainer } from 'react-toastify';


export default function PatientTabel(props) {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data?.role

  const numberFiltersAllowed = ['>', '<', '!=', '=', '<=', '>=']
  const myColumnsObject = {
    id: {
      field: 'id',
      headerName: 'Id',
      type: 'text',
      sortable: true,
      width: 120,
      editable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.id;
      }
    },
    drug_id: {
      field: 'drug_id',
      headerName: 'Drug Kit Id',
      type: 'number',
      sortable: true,
      width: 120,
      editable: false,
      filterable: true,
      valueGetter: (params) => {
        return params.row?.all_data?.drug_id;
      }
    },
    profile_photo: {
      field: 'profile_photo',
      headerName: 'Profile Photo',
      type: 'image',
      sortable: false,
      width: 120,
      editable: false,
      filterable: false,
      valueGetter: (params) => {
        const imageUrl = params.row.thumbnail?.thumbnail ? `${__API_URL__ + params.row.thumbnail?.thumbnail}` : Avatar;
        return `<img src="${imageUrl}" alt="img" style="width: 40px; height: 46px; border-radius: 50%;" object-fit: cover;" />`;
      },
      renderCell: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params?.value }}></div>;
      }
    },
    trial_name: {
      field: 'trial_name',
      headerName: 'Trial Name',
      sortable: false,
      width: 400,
      id: 'trialNameFilter',
      filterable: false,
      filterOptions: {
        names: ['Option 1', 'Option 2', 'Option 3'],
      },
      id: 'trialNameFilter',
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) => {
        if (Array.isArray(params.row?.all_data?.trials_info)) {
          return params.row.all_data?.trials_info.map((trial) => trial[0]).join(', ');
        } else {
          return params.row?.all_data?.last_visit?.time || '';
        }
      },
    },
    trial_status: {
      field: 'trial_status',
      headerName: 'Trial Status',
      sortable: false,
      width: 200,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) => {
        if (Array.isArray(params.row.all_data?.trials_info)) {
          return params.row?.all_data?.trials_info?.map((trial) => trial[1]).join(', ');
        } else {
          return params.row.last_visit?.time || '';
        }
      },
    },
    pass: {
      field: 'pass',
      headerName: 'Pass',
      type: 'text',
      width: 120,
      filterable: false,
      sortable: false,
      editable: false,
    },
    site_id: {
      field: 'site_id',
      headerName: 'Site Id',
      type: 'text',
      width: 300,
      editable: false,
      sortable: true,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) =>
        `${params.row?.all_data?.site_id || ''}`,
    },
    
    formatted_intials: {
      field: 'formatted_intials',
      headerName: user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || user_data.role === 'Cro_coordinator' || user_data.role ==='Data Manager' ? "SiteID-SubjectsID" : "SiteID-ParticipantID",
      type: 'text',
      width: 300,
      editable: false,
      sortable: false,
      filterable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) =>
        `${params.row?.all_data?.formatted_intials || ''}`,
    },
    completed_photo_session_count: {
      field: 'completed_photo_session_count',
      headerName: 'Completed Visits',
      type: 'number',
      width: 200,
      editable: false,
      sortable: true,
      filterable: true,
      filterOperators: getGridNumericOperators().filter(
        (operator) => numberFiltersAllowed.includes(operator.value)
      ),
      valueGetter: (params) => {
        return params.row?.all_data?.completed_photo_session_count;
      }
    },
    first_name: {
      field: 'first_name',
      headerName: 'First Name',
      width: 200,
      editable: false,
      sortable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) => {
        return params.row?.first_name || '';
      },
    },
    last_name: {
      field: 'last_name',
      headerName: 'Last Name',
      width: 200,
      editable: false,
      sortable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) => {
        return params.row?.last_name || '';
      },
    },
    email: {
      field: 'email',
      headerName: 'Email (Patient)',
      type: 'email',
      width: 300,
      editable: false,
      sortable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) =>
        `${params.row?.all_data?.email}`,
    },
    participant_id: {
      field: 'participant_id',
      headerName: user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || user_data.role === 'Cro_coordinator' || user_data.role ==='Data Manager' ? "Subject Id" : "Participant Id",
      type: 'text',
      width: 300,
      editable: false,
      sortable: true,
      filterable: true,
      // filterOperators: getGridNumericOperators().filter(
      //   (operator) => numberFiltersAllowed.includes(operator.value)
      // ),
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) =>
        `${params.row?.all_data?.participant_id || ''}`,
    },
    clinic: {
      field: 'clinic',
      headerName: 'Clinic',
      type: 'text',
      width: 200,
      editable: false,
      filterable: true,
      sortable: false,
      valueGetter: (params) =>
        `${params.row.clinic?.name}`,
    },
    has_trial: {
      field: 'has_trial',
      headerName: 'Has Trial',
      type: 'text',
      width: 200,
      editable: false,
      filterable: true,
      sortable: false,
      valueGetter: (params) =>
        `${params.row.all_data?.has_trial}`,
    },
    clinical_trial_status: {
      field: 'clinical_trial_status',
      headerName: 'Trial Patient Status',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.clinical_trial_status;
      },
    },
    upcoming_visit: {
      field: 'upcoming_visit',
      headerName: 'Upcoming Visit',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      valueGetter: (params) => {
        return params.row?.all_data?.upcoming_or_current_visit_dict.visit ? `${params.row?.all_data?.upcoming_or_current_visit_dict.visit } ${params.row?.all_data?.upcoming_or_current_visit_dict.ongoing ? '[Ongoing]' : ''}` : '';
      },
    },
    upcoming_visit_start_date: {
      field: 'upcoming_visit_start_date',
      headerName: 'Upcoming Visit Start Date',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      type: "dateTime",
      valueGetter: (params) => {
        return params.row?.all_data?.upcoming_or_current_visit_dict.start_date ? new Date(params?.row?.all_data?.upcoming_or_current_visit_dict.start_date + "Z") : "";
      },
    },
    external_photos_count: {
      field: 'external_photos_count',
      headerName: 'External Photos Count',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.external_photos_count
      },
    },
    external_videos_count: {
      field: 'external_videos_count',
      headerName: 'External Videos Count',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.external_videos_count
      },
    },
    external_docs_count: {
      field: 'external_docs_count',
      headerName: 'External Documents Count',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.external_docs_count
      },
    },
    query_count: {
      field: 'query_count',
      headerName: 'Query Count',
      width: 200,
      editable: false,
      sortable: true,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.query_count
      },
    },
    has_queries: {
      field: 'has_queries',
      headerName: 'Query',
      width: 200,
      editable: false,
      sortable: true,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.has_queries ? '?' : '-';
      },
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value === '?' ? 'red' : 'blue',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    total_unscheduled_events: {
      field: 'total_unscheduled_events',
      headerName: 'Unscheduled Event Count',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.total_unscheduled_events;
      },
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value > 0 ? 'darkred' : 'grey',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    total_adverse_events: {
      field: 'total_adverse_events',
      headerName: 'Adverse Event Count',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row?.all_data?.total_adverse_events;
      },
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value > 0 ? 'darkblue' : 'grey',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    phone: {
      field: 'phone',
      headerName: 'Phone',
      type: 'text',
      width: 160,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${params.row?.phone || ''}`,
    },
    total_incomplete_blocks: {
      field: 'total_incomplete_blocks',
      headerName: 'Incomplete CDQC',
      type: 'text',
      width: 160,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.total_incomplete_blocks}`,
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value > 0 ? 'green' : 'darkgrey',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    total_unfrozen_blocks: {
      field: 'total_unfrozen_blocks',
      headerName: 'Unfrozen Blocks',
      type: 'text',
      width: 160,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.total_unfrozen_blocks}`,
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value > 0 ? 'purple' : 'darkgrey',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    total_uncertified_blocks: {
      field: 'total_uncertified_blocks',
      headerName: 'Uncertified Blocks',
      type: 'text',
      width: 160,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.total_uncertified_blocks}`,
      renderCell: (params) => {
        const value = params.value;
        const style = {
          color: value > 0 ? 'red' : 'darkgrey',
          fontWeight: 'bold',
        };
        return <span style={style}>{value}</span>;
      }
    },
    last_visit: {
      field: 'last_visit',
      headerName: 'Last Visit',
      sortable: false,
      width: 200,
      filterable: false,
      valueGetter: (params) =>
        `${params.row.all_data?.last_visit?.time ? new Date(params.row.all_data?.last_visit?.time + "Z").toLocaleString() : ""}`,
    },
    log_created_at: {
      field: 'log_created_at',
      headerName: 'Last Activity (Patient)',
      sortable: true,
      width: 200,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.last_activity ? new Date(params.row.all_data?.last_activity + "Z").toLocaleString() : ""}`,
    },
    first_build_number: {
      field: 'first_build_number',
      headerName: 'First Build Number',
      sortable: false,
      width: 160,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.first_build}`,
    },
    latest_build_number: {
      field: 'latest_build_number',
      headerName: 'Latest Build Number',
      sortable: false,
      width: 160,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.latest_build}`,
    },
    request_appointment: {
      field: 'request_appointment',
      headerName: 'Request Appointment',
      sortable: false,
      width: 200,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.request_appointment?.time ? new Date(params.row.all_data?.request_appointment?.time + "Z").toLocaleString() : ""}`,
    },
    status: {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 160,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.appointment_status}`,
    },
    unread_messages: {
      field: 'unread_messages',
      headerName: 'Unread Messages',
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row.all_data?.unread_messages?.length}`,
    },
    vip: {
      field: 'vip',
      headerName: 'VIP',
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.vip == false ? "No" : "Yes"}`,
    },
    fired: {
      field: 'fired',
      headerName: 'FIRED',
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.fired == false ? "No" : "Yes"}`,
    },
    do_not_call: {
      field: 'do_not_call',
      headerName: 'DO NOT CALL',
      sortable: false,
      filterable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row?.do_not_call == false ? "No" : "Yes"}`,
    },
    birth_year: {
      field: 'birth_year',
      headerName: 'Birth Year',
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.birth_date || ''}`,
    },
    email_crc: {
      field: 'email_crc',
      headerName: 'Last Activity CRC (Email)',
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.email_crc || ''}`,
    },
    last_activity_crc: {
      field: 'last_activity_crc',
      headerName: 'Last Activity CRC (Time Stamp)',
      sortable: true,
      width: 200,
      filterable: false,
      valueGetter: (params) =>
        `${params.row?.all_data?.last_activity_crc ? new Date(params?.row?.all_data?.last_activity_crc + "Z").toLocaleString() : ""}`,
    },
    age: {
      field: 'age',
      headerName: 'Age',
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const birthDate = params.row?.birth_date || '';
        if (birthDate !== '') {
          const today = new Date();
          const birth = new Date(birthDate);
          const ageInYears = today.getFullYear() - birth.getFullYear();
          const birthMonth = birth.getMonth();
          const todayMonth = today.getMonth();
          if (todayMonth < birthMonth || (todayMonth === birthMonth && today.getDate() < birth.getDate())) {
            return ageInYears - 1;
          }
          return ageInYears;
        }
        return '';
      },
    }
  }

  const [data, setData] = useState({ results: [], count: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const clinicPatient = props.clinicPatient
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const TOKEN = localStorage.getItem("token");
  const typeOfPatient = props.typeOfPatient  
  const setRowSelectionModel = props.setRowSelectionModel
  const rowSelectionModel = props.rowSelectionModel
  const changedPatientTable = props.changedPatientTable
  const setChangedPatientTable = props.setChangedPatientTable  
  const [patientTableConfigurations, setPatientTableConfigurations] = useState({
    'filters': [],
    'sort': [],
    'noOfRows': 10,
    'density': ''
  });
  const [filterConfigurationLastStateSaved, setFilterConfigurationLastStateSaved] = useState(true)
  const [selectedValue, setSelectedValue] = useState('');
  const [trialNames, setTrialNames] = useState(new Set([' ']));

  const handleSaveFilters = async () => {
    setFilterConfigurationLastStateSaved(true)
    saveColumnProperties()
    await ApiManager.patch(`${__API_URL__}/api/patient/patient/update_filters/`, {...patientTableConfigurations, visible_column: columnVisibilityModel}, TOKEN)
  }

  const handleCSVButtonClick = () => {
    apiRef.current.exportDataAsCsv();
    toast.success('Data exported successfully!');

  };

  const handleChangeTrial = async (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setIsLoading(true);
  
    const params = `?page=${search.page}&page_size=${
      patientTableConfigurations?.noOfRows
    }&first_name=${search.first_name}&last_name=${
      search.last_name
    }&completed_photo_session_count=${
      search.completed_photo_session_count
    }&clinic=${search.clinic ? search.clinic : ''}&email=${
      search.email
    }&trial_name=${value}&trial_status=${search.trial_status}&participant_id=${
      search.participant_id
    }&site_id=${search.site_id}&ordering=${search.ordering}&has_trial=${
      search.has_trial
    }&clinical_trial_status=${
      search.clinical_trial_status
    }&patient_status=${typeOfPatient}&drug_id=${
      search.drug_id
    }&upcoming_visit_start_date=${search.upcoming_visit_start_date}`;
  
    try {
      const response = await ApiManager.get(
        `${__API_URL__}/api/patient/patient/allpatient/${params}`,
        TOKEN
      );
      setData(response.data);
    } catch (error) {
      console.error('Error fetching patient data:', error);
      toast.error('Failed to fetch patient data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Trial Name</InputLabel>
          <Select
            value={selectedValue}
            onChange={handleChangeTrial}
            label="Trial Name"
            sx={{ width: 200 }}
          >
            {Array.from(trialNames).map((trial) => (
              <MenuItem key={trial} value={trial}>
                {trial === ' ' ? 'None' : trial}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CustomDensitySelector 
        setPatientTableConfigurations={setPatientTableConfigurations}
        patientTableConfigurations={patientTableConfigurations} 
        setFilterConfigurationLastStateSaved={setFilterConfigurationLastStateSaved}/>
        {/* <GridToolbarExport /> */}
        <button 
          type="button" 
          onClick={() => handleSaveFilters()} 
          disabled={filterConfigurationLastStateSaved && true}
          className="btn btn-primary btn-sm m-2">
            Save Filters
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm my-2"
          style={{ fontSize: '12px', padding: '4px 8px' }}
          onClick={handleCSVButtonClick}
        >
          Export to Excel
        </button>
      </GridToolbarContainer>
    );
  }

  const defaultSearchObject = {
    email: "",
    first_name: "",
    last_name: "",
    participant_id: "",
    site_id: "",
    completed_photo_session_count: "",
    clinic: "",
    fired: "FIRED",
    vip: "VIP",
    do_not_call: "",
    deactive: 'True',
    archived: 'False',
    trial_name: "",
    trial_status: "",
    has_trial:"",
    clinical_trial_status:"",
    drug_id: '',
    pass: "",
    page: 1,
    itemCount: 10,
    ordering: '-log_created_at'
  }

  const [search, setSearch] = useState({});
  const [apiCallStatus, setApiCallStatus] = useState(false);
  let navigate = useNavigate();

  const handleRowClick = (params) => {
    if (role === 'Reviewer') {
      navigate(`/patient/${params.row?.id}/photos`);
    }
    else if (role === 'Investigator' || role === 'Inv_coordinator' || role === 'Sub_investigator' || role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') {
      navigate(`/patient/${params.row?.id}/ecrf`);
    }
    else if (role === 'Super Admin' || role == 'Clinic Owner' || role == 'Clinic Admin' || role === 'Practitioner') {
      navigate(`/patient/${params.row?.id}/info`);
    }
  }

  const GetPatientFilters = async () => {
    try {
      const response = await ApiManager.get(`${__API_URL__}/api/patient/patient/get_patient_filters/`, TOKEN);
      setPatientTableConfigurations(prevConfig => ({
        filters: response.data.filters,
        sort: response.data.sort,
        density: response.data.density,
        noOfRows: response.data.rows ? response.data.rows : 10,
      }));
      setColumnVisibilityModel(response.data.visible_column)
      setApiCallStatus(true)

    } catch (error) {
      console?.error(error);
      return []
    }
  };

const onFilterChange = (filterModel) => {
    let customObject = {};

    setPatientTableConfigurations((prev) => {return  {...prev, filters: filterModel.items}}) 
    
    filterModel.items.forEach((item) => {
      if (!item.value) item.value = "";
      if (['completed_photo_session_count', 'upcoming_visit_start_date'].includes(item.field)) {
        customObject[item.field] = JSON.stringify({ 'value': item.value, 'operator': item.operator });
      } else {
        customObject[item.field] = item.value;
      }
    });

    setSearch((search) => ({
      ...defaultSearchObject,
      ...customObject,
      page: 1,
    }));
    setFilterConfigurationLastStateSaved(false)
  };
  
  useEffect(() => {
    if (!initialRenderForSearch.current) {
      setIsLoading(true);
      const params = `?page=${search.page}&page_size=${patientTableConfigurations?.noOfRows}&first_name=${search.first_name}&last_name=${search.last_name}&completed_photo_session_count=${search.completed_photo_session_count}&clinic=${search.clinic ? (search.clinic) : ('')}&email=${search.email}&trial_name=${search.trial_name}&trial_status=${search.trial_status}&participant_id=${search.participant_id}&site_id=${search.site_id}&ordering=${search.ordering}&has_trial=${search.has_trial}&clinical_trial_status=${search.clinical_trial_status}&patient_status=${typeOfPatient}&drug_id=${search.drug_id}&upcoming_visit_start_date=${search.upcoming_visit_start_date}`;
      
      ApiManager.get(`${__API_URL__}/api/patient/patient/allpatient/${params}`, TOKEN).then((response) => {
        setData(response.data);
        setIsLoading(false);
      });
    } else initialRenderForSearch.current = false;
  }, [search, typeOfPatient, clinicPatient, changedPatientTable===true]);
  
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: patientTableConfigurations.noOfRows });
  const initialRenderForPageSize = useRef(true)
  const initialRenderForPageNumber = useRef(true)
  const initialRenderForSearch = useRef(true)
  
  useEffect(() => {
    if (!initialRenderForPageNumber.current) {
      setSearch({
        ...search,
        page: paginationModel.page + 1,
      });
    } else initialRenderForPageNumber.current = false
  }, [paginationModel.page]);

  useEffect(() => {
    if (apiCallStatus) {

      let customObject = {}
      patientTableConfigurations.filters.map(filter => {
        if (!filter.value) {
          filter.value = "";
        }
        if (filter.field === 'completed_photo_session_count') {
          customObject[filter.field] = JSON.stringify({ value: filter.value, operator: filter.operator });
        } else {
          customObject[filter.field] = filter.value;
          customObject.value = filter.value;
          customObject.operator = filter.operator;
        }
      })

      patientTableConfigurations.sort.map((sortObject) => {
        const ordering = sortObject.sort === 'desc' ? `-${sortObject.field}` : sortObject.field;
        customObject['ordering'] = ordering
      })

      setSearch({...defaultSearchObject, ...customObject, itemCount : patientTableConfigurations.noOfRows})
      setPaginationModel((prev) => {return {...prev, pageSize: patientTableConfigurations.noOfRows}})
    }

  }, [apiCallStatus]);

  useEffect(() => {
    if (!initialRenderForPageSize.current) {
      setSearch({
        ...search,
        itemCount: paginationModel.pageSize,
      });
      setPatientTableConfigurations((prev) => {return {...prev, noOfRows: paginationModel.pageSize}})
      setFilterConfigurationLastStateSaved(false)
    } else initialRenderForPageSize.current = false
    
  }, [paginationModel.pageSize]);

  useEffect(() => {
    if (data.results) {
      data.results.forEach((data) => {
        setTrialNames((prev) => {
          const newTrialNames = prev;
          if (data.all_data && data.all_data.trials_info && data.all_data.trials_info[0] && data.all_data.trials_info[0][0]) {
            newTrialNames.add(data.all_data.trials_info[0][0]);
          }
          return newTrialNames;
        });
      });
    }
  }, [data]);

  const handleSortModelChange = (sortModel) => {
    setPatientTableConfigurations((prev) => ({ ...prev, sort: sortModel }));

    let ordering;
    if (sortModel && sortModel.length > 0) {
      const field = sortModel[0]?.field;
      const sort = sortModel[0]?.sort;
      if (field && sort) {
        if (sort === 'desc') {
          ordering = `-${field}`;
        } else if (sort === 'asc') {
          ordering = field;
        } else {
          ordering = '';
        }
        setSearch((search) => ({
          ...search,
          ordering,
          page: 1,
        }));
      }
    }else{
      setSearch((search) => ({
        ...search,
        ordering:'unsort',
        page: 1,
      }));
    }
    setFilterConfigurationLastStateSaved(false);
  };

  const getAllColumns = async () => {
    const response = await ApiManager.get(`${__API_URL__}/api/patient/patient/get_columns/`, TOKEN);
    let columnList = []
    response.data.map((col) => {
      if (myColumnsObject[col.field]) {
        columnList.push({...myColumnsObject[col.field], width: col.column_width})
      }
    })
    setColumns(columnList)
  }

  const saveColumnProperties = async () => {
    let customColumnList = []
    let count = 1
    apiRef.current.getAllColumns().map((col) => {
        if (col.field !== "__check__") {
          let customColumnObject = {
              field: col.field,
              order_number: count,
              column_width: col.width
          }
          customColumnList.push(customColumnObject)
          count += 1
        }
    })
    await ApiManager.patch(`${__API_URL__}/api/patient/patient/update_columns/`, customColumnList, TOKEN);
  }
  
  useEffect(() => {
    GetPatientFilters();
    getAllColumns()
  }, [])

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', () => setFilterConfigurationLastStateSaved(false));
  }, [apiRef]);


  return (
    <Box className="data-grid-size">
      <DataGridPro
        apiRef={apiRef}
        columnVisibilityModel={columnVisibilityModel}
        density={patientTableConfigurations?.density}
        onColumnVisibilityModelChange={(newModel) => {setColumnVisibilityModel(newModel); setFilterConfigurationLastStateSaved(false)}}
        slots={{ toolbar: CustomToolbar }}
        rows={data?.results}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={handleRowClick}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        rowCount={data?.count}
        pageSizeOptions={[10, 25, 50, 100]}
        onColumnResize={() => setFilterConfigurationLastStateSaved(false)}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        filterModel={{
          items: patientTableConfigurations.filters,
        }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                style: { display: 'none' },
              },
            },
          },
        }}
        sortModel={patientTableConfigurations.sort}
        rowSelectionModel={rowSelectionModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        sortingMode="server"
        loading={isLoading}
        onSortModelChange={handleSortModelChange}
      />
      <ToastContainer/>
    </Box>
  );
}
